<template>
  <div class="main-div">
    <div class="container">
      <div class="columns is-mobile">
        <div class="column is-10 is-offset-1 is-centered">
          <div class="is-flex is-flex-direction-column is-align-items-center">
            <div class="sticky-header">
              <figure class="image image-custom-size mt-5 has-text-centered">
                <img src="https://i.imgur.com/uA3fRLZ.png" alt="logo">
              </figure>
              <h1 class="title has-text-centered" style="font-style: italic;">Wisevet live Data Privacy Statement</h1>
            </div>
            <p class="content">
              Your privacy is very important to us. Please read below to understand how we handle your information.
            </p>
            <p class="content has-text-justified">
              This Privacy Statement describes the personal data we collect and/or process (which may
              include collecting, organizing, structuring, storing, using, or disclosing) to provide products
              and services offered directly by Wisevet live API from Vetexpertise Lda. (“Wisevet live”),
              including websites incorporating Wisevet live, its online consultations and messaging
              platform and related collaborative features (“Wisevet live products and services” or “products
              and services”). Wisevet live products and services covered in this Privacy Statement do not
              include products or services developed by Wisevet live that are covered under a separate
              privacy policy (including those processed by WhatsApp, Stripe, Jitsi, Aurinko, EZ4U, Google
              and others).
            </p>
            <ol class="content has-text-justified roman-list">
              <li>What Personal Data Do We Receive? How Do We Use Personal Data?</li>
              <li>How Do We Share Personal Data?</li>
              <li>Who Can See, Share, and Process My Personal Data When I Join Meetings and Use Wisevet live Products and Services?</li>
              <li>Privacy Rights and Choices</li>
              <li>Children</li>
              <li>How to Contact Us Retention</li>
              <li>European Data Protection Specific Information Changes to This Privacy Statement</li>
              <li>Legal Basis for Processing Personal Data</li>
              <li>International Data Transfers</li>
              <li>Changes to This Privacy Statement</li>
            </ol>
            <h2 class="subtitle mt-5 roman-numeral">What Personal Data Do We Receive?</h2>
            <p class="content has-text-justified">
              Personal data is any information from or about an identified or identifiable person, including
              information that Wisevet live can associate with an individual person. We may collect, or
              process on behalf of our customers, the following categories of personal data when you use
              or interact with Wisevet live products and services:
            </p>
            <p class="content has-text-justified">
              <span class="highlighted-text">Account Information:</span> Information associated with an account/website that licenses Wisevet
              live products and services, which may include administrator name, contact information,
              account ID, billing and transaction information.
            </p>
            <p class="content has-text-justified">
              Profile and Pet Parent / Client Information: Information associated with the Wisevet live
              profile of a pet parent / client who uses Wisevet live products and services under a licensed
              account joining an online consultation, which may include name, display name, picture, email
              address, phone number, WhatsApp number, address, job information, user ID, or other
              information provided by the user and/or their account owner.
            </p>
            <p class="content has-text-justified">
              Pet Information: Information associated with the Wisevet live profile of a pet associated to a
              pet parent / client profile who uses Wisevet live products and services under a licensed
              account joining an online consultation, which may include name, breed, picture, gender,
              reproductive state, age or other information provided by the user and/or their account owner.
            </p>
            <p class="content has-text-justified">
              Contact Information: Contact information added by accounts and/or their licensed end users
              to create contact lists on Wisevet live products and services, which may include contact
              information a user integrates from a third-party app, or provided by users to process referral
              invitations.
            </p>
            <p class="content has-text-justified">
              Settings: Information associated with the preferences and settings on a Wisevet live account
              or user profile, which may include audio and video settings, recording file location, file
              uploading (pdf, jpeg, mp3, mp4, etc.), screen sharing settings, and other settings and
              configuration information.
            </p>
            <p class="content has-text-justified">
              Registration Information: Information provided when registering for a Wisevet live online
              consultation, which may include name and contact information, responses to registration
              questions, and other registration information requested by the host.
            </p>
            <p class="content has-text-justified">
              Device Information: Information about the computers, phones, and other devices used when
              interacting with Wisevet live products and services, which may include information about the
              speakers, microphone, camera, OS version, hard disk ID, PC name, MAC address, IP
              address (which may be used to infer general location at a city or country level), device
              attributes (like operating system version and battery level), WiFi information, and other device
              information (like Bluetooth signals).
            </p>
            <p class="content has-text-justified">
              Content and Context from Online Consultations, Messages and Other Collaborative
              Features: Content generated form online consultations, or messages that are hosted on
              Wisevet live products and services (“Customer Content”), which may include audio, video,
              in-consultation messages, in-consultation and out-of-consultation whiteboards, chat
              messaging content, transcriptions, transcript edits and recommendations, responses to
              account owner / host-sponsored post-consultation feedback requests, and files, as well as
              related context, such as invitation details, consultation or chat name, or consultation agenda.
              Customer Content may contain their voice and image, depending on the account owner’s
              settings, what they choose to share, their settings, and what they do on Wisevet live products
              and services. As referenced below, Wisevet live employees do not access or use Customer
              Content without the authorization of the hosting account owner, or as required for legal,
              safety, or security reasons.
            </p>
            <p class="content has-text-justified">
              Usage Information Regarding Online Consultations, Messaging, Collaborative Features and
              the Website: Information about how people and their devices interact with Wisevet live
              products and services, such as: when participants join and leave an online consultation;
              whether participants sent messages; performance data; mouse movements, clicks,
              keystrokes or actions (such as mute/unmute or video on/off), edits to transcript text, where
              authorized by the account owner and other inputs that help Wisevet live to understand feature
              usage, improve product design, and suggest features; which third-party apps are added to a
              meeting or other product or service and what information and actions the app is authorized
              to access and perform; use of third-party apps; features used (such as screen sharing,
              emojis, or filters); and other usage information and metrics.
            </p>
            <p class="content has-text-justified">
              Limited Information from Wisevet live Email: “Wisevet live Email” refers to Wisevet live’s
              native email service and emails sent from Wisevet live’s native email service. Wisevet live
              Email is designed to be end-to-end encrypted by Wisevet live by default for emails sent and
              received directly between active Wisevet live Email users. Support for end-to-end encryption
              requires Wisevet live Email users to have added a device to their Wisevet live Email account
              with the associated email address and to use a supported Wisevet live client. When an email
              is end-to-end encrypted, only the users, and, depending on their settings, account owners,
              or designated account administrators control the encryption key and therefore access to the
              email content, including body text, subject line, attachments and custom labels applied to
              messages by users in their inboxes. Wisevet live does have access to email metadata used
              for basic email delivery—specifically, email addresses in the from, to, cc, and bcc fields, time,
              mimeID, and the number and size of attachments.
            </p>
            <p class="content has-text-justified">
              Google Calendars’ Information: Information from all Google Calendars each veterinarian has
              access through the Google account they choose to associate in the veterinarian profile in the
              application. To associate the Google account the application uses an OAuth2 open
              authorization framework. The information received allows the application to view and
              manage events across all calendars the associated Google account has access to.
            </p>
            <p class="content has-text-justified">
              Content from Third-Party Integrations: Users can access email and calendars from thirdparty
              services through their Wisevet live client, if they choose to integrate them. This
              information is not end-to-end encrypted by Wisevet live, but Wisevet live employees do not
              access the contents of third-party-service email or calendar entries, unless authorized to, or
              required for legal, safety, or security reasons.
            </p>
            <p class="content has-text-justified">
              Communications with Wisevet live: Information about, and contents of, your communications
              with Wisevet live, including relating to support questions, website virtual chats, your account,
              feedback provided by users to Wisevet live about Wisevet live’s products and services (which
              is owned by Wisevet live), and other inquiries.
            </p>
            <p class="content has-text-justified">
              Information from Partners: Wisevet live obtains information about account owners and their
              users from third-party companies, such as market data enrichment services, including
              information about an account owner’s company size or industry, contact information, or
              activity of certain enterprise domains. Wisevet live may also obtain information from thirdparty
              advertising partners that deliver ads displayed on Wisevet live products and services,
              such as whether you clicked on an ad they showed you.
            </p>
            <p class="content has-text-justified">
              In certain jurisdictions, some of the personal data Wisevet live receives may be considered
              sensitive.
            </p>

            
            <ul class="content">
              <li>Sign up for our services</li>
              <li>Use our application</li>
              <li>Contact customer support</li>
            </ul>

            <h2 class="subtitle">Data Usage</h2>
            <ol class="content">
              <li>Your data helps us improve our services.</li>
              <li>We may use your data to personalize your experience.</li>
              <li>Your data will not be sold or shared with third parties without consent.</li>
            </ol>

            <h2 class="subtitle">Your Rights</h2>
            <p class="content">
              You have the right to:
            </p>
            <ul class="content">
              <li>Access your data</li>
              <li>Request corrections</li>
              <li>Delete your data</li>
            </ul>
            <p class="control">
              <a @click="goBack()" class="button is-success">
                {{ $t('General.Back') }}
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">

export default {
  name: "DataPrivacyPolicy",
}
</script>

<script setup lang="ts">

import { useRouter, useRoute } from 'vue-router';
import { Notyf } from "notyf";
import { useI18n } from 'vue-i18n';
import { onBeforeMount } from 'vue';
import { changeLocale } from "@/main"; // Import the changeLocale function


const router = useRouter();
const route = useRoute()
const { locale } = useI18n();
const notyf = new Notyf()
const lang = route.query.lang as string;

const goBack = () => {
  router.go(-1);
}

onBeforeMount(async() => {

  if (lang)
  {
    try
    {
      await changeLocale(lang);
    }
    catch (error)
    {
      console.error('Failed to change language:', error);
    }
  }

})

</script>

<style scoped>
* {
  font-family: Helvetica, Arial, sans-serif;
}
.image-custom-size {
  width: 256px;
  height: auto; /* maintains aspect ratio */
  margin: 0 auto; /* Center image horizontally */
}
.main-div {
  display: flex;
  align-items: center;
}
.sticky-header {
  position: sticky; /* Makes the header sticky */
  top: 0; /* Sticks to the top */
  background-color: white; /* Background color for visibility */
  z-index: 1000; /* Ensure it stays above other content */
  text-align: center; /* Center the content */
  padding: 1rem 0; /* Add some padding */
  width: 100%; /* Full width */
}
.roman-list {
      list-style-type: lower-roman; /* Use Roman numerals */
      margin: 1rem 0; /* Adjust top and bottom margins */
       padding-left: 2rem; /* Indent the list */
 }
.highlighted-text {
  color: teal;
  font-style: italic;
}
/* Custom class for Roman numeral subtitles */
.roman-numeral {
  counter-increment: section;
  margin-top: 2rem;
  text-align: left; /* Left-aligns the subtitle text */
  padding-left: 0; /* Ensure no extra padding */
}

.roman-numeral::before {
  content: counter(section, lower-roman) ". ";
  font-weight: bold;
  padding-right: 0.5rem; /* Space between numeral and text */
  display: inline-block;
}
</style>
