<template>
  <td class = "sticky-column">
    <div>{{ petData.name }}</div>
  </td>
  <td>
    <div>{{ petData.species }}</div>
  </td>
  <td>
    <div>{{ petData.breed }}</div>
  </td>
  <td>
    <div>{{ petData.gender }}</div>
  </td>
  <td>
    <div>{{ petData.birth }}</div>
  </td>
  <td>
    <span v-if="ageYears == 1">{{ ageYears }} {{ $t('General.Year') }}</span>
    <span v-if="ageYears > 1">{{ ageYears }} {{ $t('General.Years') }}</span>
    <span v-if="ageMonths == 1">{{ ageMonths }} {{ $t('General.Month') }}</span>
    <span v-if="ageMonths > 1">{{ ageMonths }} {{ $t('General.Months') }}</span>
    <span v-if="(ageYears == 0 && ageMonths == 0 && ageDays == 1)">{{ ageDays }} {{ $t('General.Day') }}</span>
    <span v-if="(ageYears == 0 && ageMonths == 0 && ageDays > 1)">{{ ageDays }} {{ $t('General.Days') }}</span>
  </td>
  <td>
    <div>{{ petData.fertile_status }}</div>
  </td>
  <td>
    <div>{{ petData.weight }}</div>
  </td>
  <td>
    <div class="is-flex is-justify-content-start" style="gap: 1em;">
      <div>
        <button type="button" v-if="!editingPet() && editingCustomer() && !showingPetRecord()"
          class="button is-warning is-small" @click="iniEditing(petData)">{{ $t('General.Edit') }}</button>
      </div>
      <div v-if="!editingPet() && editingCustomer() && !showingPetRecord()">
        <button type="button" class="button is-danger is-small" @click="remove(petData, $t)">{{ $t('General.Delete') }}</button>
      </div>
      <div v-if="!editingCustomer() && !showingPetRecord()">
        <button type='button' class="button is-danger is-small" @click="iniRecord(petData)">{{ $t('General.Record') }}</button>
      </div>
    </div>
  </td>
</template>


<script lang="ts">
import { defineComponent, defineProps, onBeforeMount, onMounted } from 'vue'
import { useStore } from "vuex";
import { Notyf } from "notyf";
import { differenceInYears, differenceInMonths, differenceInDays } from "date-fns";

const store = useStore()

const notyf = new Notyf()

export default defineComponent({
  name: "CustDataPetTableRow",
})

</script>
<script lang="ts" setup>

const props = defineProps(['petData', 'language'])

const store = useStore()
var ageYears: number;
var ageMonths: number;
var ageDays: number;

function editingPet(): boolean {
  console.log(store.getters.getEditedCustEditingPet)
  return (store.getters.getEditedCustEditingPet)
}

function showingPetRecord(): boolean {
  console.log(store.getters.getShowingPetRecord)
  return (store.getters.getShowingPetRecord)
}

function editingCustomer(): boolean {
  console.log(store.getters.getEditingCustomer)
  return (store.getters.getEditingCustomer)
}

function iniEditing(editingPetData: any) {
  console.log("iniEditing")
  console.log(store.getters.getEditedCustEditingPet)
  console.log(store.getters.getEditedCustNewPetData)
  store.dispatch('iniEditedCustPetEditing', editingPetData)
  store.dispatch('toggleEditedCustPetEditing', false)
  console.log(store.getters.getEditedCustNewPetData)
  console.log(store.getters.getEditedCustEditingPet)
}

async function iniRecord(showRecordPetData: any) {
  console.log("iniRecord")
  console.log(store.getters.getShowingPetRecord)
  console.log(showRecordPetData)
  if(showRecordPetData.pet_id != 0)
  {
    await store.dispatch('iniPetRecord', {editingPetData: showRecordPetData, language : props.language})
    console.log("back to iniRecord")
    console.log(store.getters.getPetRecord)
    store.dispatch('toggleShowingPetRecord', false)
  }
  else
  {
    console.log("Error: Pet ID is 0")
  }
}


const remove = async (petData: any, $t: any) => {
  if (confirm($t && $t('PetData.ConfirmDelete'))) {
    //TODO:Create notification to ask if it is really to delete
    console.log("remove")
    console.log(store.getters.getEditedCustomerPets)
    if (petData.pet_id != 0) {
      // Store pet to be deleted in the DB when saving data.
      // Only of it hasn't been added in this interaction
      await store.dispatch('savePetToBeDeleted', petData.pet_id)
    }
    try {
      // Remove pet from the list front end
      await store.dispatch('removePetEditedCustomer', petData.pet_pos)
      console.log(store.getters.getEditedCustomerPets)
      notyf.success($t('PetData.NotyfPetRemoved'))
    } catch (e) {
      notyf.error($t('PetData.NotyfErrorRemovePet'))
    }
  }
}

onBeforeMount(() => {
  console.log(props.petData.birth)
  const birthDate = new Date(props.petData.birth);
  console.log(birthDate)
  ageYears = differenceInYears(new Date(), birthDate);
  console.log(ageYears)
  ageMonths = differenceInMonths(new Date(), birthDate) - 12 * ageYears;
  console.log(ageMonths)
  ageDays = differenceInDays(new Date(), birthDate);
  console.log(ageDays);

})

onMounted(() => {
})

</script>
<style scoped>
.pointer {
  cursor: pointer;
}
.sticky-column {
  position: sticky;
  max-width: 150px; /* Ensure it doesn't expand beyond this width */
  left: 0;
  background: #fff; /* Match table background */
  z-index: 2;
  padding-left: 5px; 
  border-right: 1px solid #ddd;
  border-left: 1px solid #ddd;
  will-change: left; /* Optimize performance */
} 

</style>
