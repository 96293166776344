import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, withModifiers as _withModifiers, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-cef2455c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "hero" }
const _hoisted_2 = { class: "hero-body" }
const _hoisted_3 = { class: "container" }
const _hoisted_4 = { class: "content" }
const _hoisted_5 = { class: "content-body" }
const _hoisted_6 = { class: "header" }
const _hoisted_7 = {
  key: 0,
  class: "title is-5"
}
const _hoisted_8 = {
  key: 1,
  class: "title is-5"
}
const _hoisted_9 = { class: "columns" }
const _hoisted_10 = { class: "column is-half" }
const _hoisted_11 = { class: "field" }
const _hoisted_12 = { class: "label" }
const _hoisted_13 = { class: "control" }
const _hoisted_14 = ["placeholder", "disabled"]
const _hoisted_15 = { class: "column" }
const _hoisted_16 = { class: "field" }
const _hoisted_17 = { class: "label" }
const _hoisted_18 = { class: "control" }
const _hoisted_19 = ["placeholder", "disabled"]
const _hoisted_20 = { class: "column" }
const _hoisted_21 = { class: "field" }
const _hoisted_22 = { class: "label" }
const _hoisted_23 = { class: "control" }
const _hoisted_24 = ["placeholder", "disabled"]
const _hoisted_25 = { class: "column" }
const _hoisted_26 = { class: "field" }
const _hoisted_27 = { class: "label" }
const _hoisted_28 = { class: "control" }
const _hoisted_29 = ["placeholder", "disabled"]
const _hoisted_30 = { class: "columns" }
const _hoisted_31 = { class: "column" }
const _hoisted_32 = { class: "field" }
const _hoisted_33 = { class: "label" }
const _hoisted_34 = { class: "control" }
const _hoisted_35 = ["placeholder", "disabled"]
const _hoisted_36 = { class: "column" }
const _hoisted_37 = { class: "field" }
const _hoisted_38 = { class: "label" }
const _hoisted_39 = { class: "control" }
const _hoisted_40 = ["placeholder", "disabled"]
const _hoisted_41 = { class: "header" }
const _hoisted_42 = {
  key: 0,
  class: "title is-6 mt-4"
}
const _hoisted_43 = { class: "content-body" }
const _hoisted_44 = { class: "table-container" }
const _hoisted_45 = {
  key: 0,
  class: "table"
}
const _hoisted_46 = { class: "sticky-column" }
const _hoisted_47 = { class: "" }
const _hoisted_48 = { class: "" }
const _hoisted_49 = { class: "" }
const _hoisted_50 = { class: "" }
const _hoisted_51 = { class: "" }
const _hoisted_52 = { class: "" }
const _hoisted_53 = { class: "" }
const _hoisted_54 = { class: "content-body" }
const _hoisted_55 = {
  key: 0,
  class: "header is-flex is-justify-content-space-between"
}
const _hoisted_56 = { class: "content-body" }
const _hoisted_57 = { class: "field is-grouped is-grouped-right" }
const _hoisted_58 = {
  key: 0,
  class: "control"
}
const _hoisted_59 = {
  type: "submit",
  class: "button is-primary"
}
const _hoisted_60 = {
  key: 1,
  class: "control"
}
const _hoisted_61 = {
  key: 2,
  class: "control"
}
const _hoisted_62 = {
  key: 3,
  class: "control"
}
const _hoisted_63 = { class: "content-body" }
const _hoisted_64 = { class: "header" }
const _hoisted_65 = { class: "title is-5" }
const _hoisted_66 = { class: "field is-grouped is-grouped-right" }
const _hoisted_67 = { class: "control" }
const _hoisted_68 = {
  type: "submit",
  class: "button is-primary"
}
const _hoisted_69 = { class: "control" }
const _hoisted_70 = { class: "content-body" }
const _hoisted_71 = { class: "header" }
const _hoisted_72 = { class: "title is-5" }
const _hoisted_73 = { class: "field is-grouped is-grouped-right" }
const _hoisted_74 = { class: "control" }
const _hoisted_75 = {
  type: "submit",
  class: "button is-primary"
}
const _hoisted_76 = { class: "control" }
const _hoisted_77 = { class: "content-body" }
const _hoisted_78 = { class: "header" }
const _hoisted_79 = { class: "title is-5" }
const _hoisted_80 = { class: "field is-grouped is-grouped-right" }
const _hoisted_81 = { class: "control" }
const _hoisted_82 = {
  type: "submit",
  class: "button is-primary"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Navbar = _resolveComponent("Navbar")!
  const _component_CustDataPetTableRow = _resolveComponent("CustDataPetTableRow")!
  const _component_CustDataPetDataForm = _resolveComponent("CustDataPetDataForm")!
  const _component_AppointmentDataForm = _resolveComponent("AppointmentDataForm")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_Navbar),
    _createElementVNode("section", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            (!_ctx.getShowingAppointmentData)
              ? (_openBlock(), _createElementBlock("form", {
                  key: 0,
                  onSubmit: _cache[16] || (_cache[16] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.editCustomerMeth && _ctx.editCustomerMeth(...args)), ["prevent"]))
                }, [
                  _createElementVNode("div", _hoisted_5, [
                    (!_ctx.getShowingPetRecord)
                      ? (_openBlock(), _createElementBlock("form", {
                          key: 0,
                          onSubmit: _cache[6] || (_cache[6] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.editCustomerMeth && _ctx.editCustomerMeth(...args)), ["prevent"]))
                        }, [
                          _createElementVNode("div", _hoisted_6, [
                            (_ctx.getEditingCustomer)
                              ? (_openBlock(), _createElementBlock("h5", _hoisted_7, _toDisplayString(_ctx.$t('CustomerData.PleaseModifyYourCustomerData')), 1))
                              : _createCommentVNode("", true),
                            (!_ctx.getEditingCustomer)
                              ? (_openBlock(), _createElementBlock("h5", _hoisted_8, _toDisplayString(_ctx.$t('CustomerData.YourCustomerData')), 1))
                              : _createCommentVNode("", true)
                          ]),
                          _createElementVNode("div", _hoisted_9, [
                            _createElementVNode("div", _hoisted_10, [
                              _createElementVNode("div", _hoisted_11, [
                                _createElementVNode("label", _hoisted_12, _toDisplayString(_ctx.$t('CustomerData.Name')), 1),
                                _createElementVNode("div", _hoisted_13, [
                                  _withDirectives(_createElementVNode("input", {
                                    minlength: 2,
                                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.getEditedCustomerData.name) = $event)),
                                    class: "input",
                                    type: "text",
                                    placeholder: _ctx.$t('CustomerData.NamePlaceholder'),
                                    required: "",
                                    disabled: !_ctx.getEditingCustomer
                                  }, null, 8, _hoisted_14), [
                                    [_vModelText, _ctx.getEditedCustomerData.name]
                                  ])
                                ])
                              ])
                            ]),
                            _createElementVNode("div", _hoisted_15, [
                              _createElementVNode("div", _hoisted_16, [
                                _createElementVNode("label", _hoisted_17, _toDisplayString(_ctx.$t('CustomerData.Email')), 1),
                                _createElementVNode("div", _hoisted_18, [
                                  _withDirectives(_createElementVNode("input", {
                                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.getEditedCustomerData.email) = $event)),
                                    class: "input",
                                    type: "email",
                                    placeholder: _ctx.replaceSpecialCharacters(_ctx.$t('CustomerData.EmailPlaceholder')),
                                    required: "",
                                    disabled: !_ctx.getEditingCustomer
                                  }, null, 8, _hoisted_19), [
                                    [_vModelText, _ctx.getEditedCustomerData.email]
                                  ])
                                ])
                              ])
                            ]),
                            _createElementVNode("div", _hoisted_20, [
                              _createElementVNode("div", _hoisted_21, [
                                _createElementVNode("label", _hoisted_22, _toDisplayString(_ctx.$t('UserData.PhoneNumber')), 1),
                                _createElementVNode("div", _hoisted_23, [
                                  _withDirectives(_createElementVNode("input", {
                                    minlength: 6,
                                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.getEditedCustomerData.phone) = $event)),
                                    class: "input",
                                    type: "tel",
                                    placeholder: _ctx.$t('UserData.PhoneNumberPlaceholder'),
                                    required: "",
                                    disabled: !_ctx.getEditingCustomer
                                  }, null, 8, _hoisted_24), [
                                    [_vModelText, _ctx.getEditedCustomerData.phone]
                                  ])
                                ])
                              ])
                            ]),
                            _createElementVNode("div", _hoisted_25, [
                              _createElementVNode("div", _hoisted_26, [
                                _createElementVNode("label", _hoisted_27, _toDisplayString(_ctx.$t('CustomerData.VATNumber')), 1),
                                _createElementVNode("div", _hoisted_28, [
                                  _withDirectives(_createElementVNode("input", {
                                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.getEditedCustomerData.tax_id) = $event)),
                                    class: "input",
                                    type: "text",
                                    placeholder: _ctx.$t('CustomerData.VATNumberPlaceholder'),
                                    required: "",
                                    disabled: !_ctx.getEditingCustomer
                                  }, null, 8, _hoisted_29), [
                                    [_vModelText, _ctx.getEditedCustomerData.tax_id]
                                  ])
                                ])
                              ])
                            ])
                          ]),
                          _createElementVNode("div", _hoisted_30, [
                            _createElementVNode("div", _hoisted_31, [
                              _createElementVNode("div", _hoisted_32, [
                                _createElementVNode("label", _hoisted_33, _toDisplayString(_ctx.$t('CustomerData.Address')), 1),
                                _createElementVNode("div", _hoisted_34, [
                                  _withDirectives(_createElementVNode("input", {
                                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.getEditedCustomerData.address) = $event)),
                                    class: "input",
                                    type: "text",
                                    placeholder: _ctx.$t('CustomerData.AddressPlaceholder'),
                                    required: "",
                                    disabled: !_ctx.getEditingCustomer
                                  }, null, 8, _hoisted_35), [
                                    [_vModelText, _ctx.getEditedCustomerData.address]
                                  ])
                                ])
                              ])
                            ]),
                            _createElementVNode("div", _hoisted_36, [
                              _createElementVNode("div", _hoisted_37, [
                                _createElementVNode("label", _hoisted_38, _toDisplayString(_ctx.$t('CustomerData.VetCenter')), 1),
                                _createElementVNode("div", _hoisted_39, [
                                  _withDirectives(_createElementVNode("input", {
                                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.getEditedCustomerData.vet_center) = $event)),
                                    class: "input",
                                    type: "text",
                                    placeholder: _ctx.$t('CustomerData.VetCenterPlaceholder'),
                                    required: "",
                                    disabled: !_ctx.getEditingCustomer
                                  }, null, 8, _hoisted_40), [
                                    [_vModelText, _ctx.getEditedCustomerData.vet_center]
                                  ])
                                ])
                              ])
                            ])
                          ])
                        ], 32))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("div", _hoisted_41, [
                    (!_ctx.addingPet && !_ctx.getEditedCustEditingPet && !_ctx.getShowingPetRecord)
                      ? (_openBlock(), _createElementBlock("h5", _hoisted_42, _toDisplayString(_ctx.$t('CustomerData.YourPets')), 1))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("div", _hoisted_43, [
                    _createElementVNode("div", _hoisted_44, [
                      (!_ctx.addingPet && !_ctx.getEditedCustEditingPet && !_ctx.getShowingPetRecord)
                        ? (_openBlock(), _createElementBlock("table", _hoisted_45, [
                            _createElementVNode("thead", null, [
                              _createElementVNode("tr", null, [
                                _createElementVNode("th", _hoisted_46, _toDisplayString(_ctx.$t('PetData.PetName')), 1),
                                _createElementVNode("th", _hoisted_47, _toDisplayString(_ctx.$t('PetData.Species')), 1),
                                _createElementVNode("th", _hoisted_48, _toDisplayString(_ctx.$t('PetData.Breed')), 1),
                                _createElementVNode("th", _hoisted_49, _toDisplayString(_ctx.$t('PetData.Gender')), 1),
                                _createElementVNode("th", _hoisted_50, _toDisplayString(_ctx.$t('PetData.Birth')), 1),
                                _createElementVNode("th", _hoisted_51, _toDisplayString(_ctx.$t('PetData.Age')), 1),
                                _createElementVNode("th", _hoisted_52, _toDisplayString(_ctx.$t('PetData.FertileStatus')), 1),
                                _createElementVNode("th", _hoisted_53, _toDisplayString(_ctx.$t('PetData.Weight')), 1),
                                _createElementVNode("th", null, _toDisplayString(_ctx.$t('General.Operations')), 1)
                              ])
                            ]),
                            _createElementVNode("tbody", null, [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getEditedCustomerPets, (pet) => {
                                return (_openBlock(), _createElementBlock("tr", {
                                  key: pet.pet_id
                                }, [
                                  _createVNode(_component_CustDataPetTableRow, {
                                    "pet-data": pet,
                                    language: _ctx.language
                                  }, null, 8, ["pet-data", "language"])
                                ]))
                              }), 128))
                            ])
                          ]))
                        : _createCommentVNode("", true)
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_54, [
                    (!_ctx.addingPet && !_ctx.getEditedCustEditingPet
              && _ctx.getEditingCustomer && !_ctx.getShowingPetRecord)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_55, [
                          _createElementVNode("div", null, [
                            _createElementVNode("button", {
                              class: "button is-primary",
                              onClick: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.toggleAddingPet && _ctx.toggleAddingPet(...args)))
                            }, [
                              _createElementVNode("strong", null, _toDisplayString(_ctx.$t('CustomerData.NewPet')), 1)
                            ])
                          ])
                        ]))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("div", _hoisted_56, [
                    _createElementVNode("div", _hoisted_57, [
                      (!_ctx.addingPet && !_ctx.getEditedCustEditingPet && _ctx.getEditingCustomer && !_ctx.getShowingPetRecord)
                        ? (_openBlock(), _createElementBlock("p", _hoisted_58, [
                            _createElementVNode("button", _hoisted_59, _toDisplayString(_ctx.$t('General.Save')), 1)
                          ]))
                        : _createCommentVNode("", true),
                      (!_ctx.addingPet && !_ctx.getEditedCustEditingPet && _ctx.getEditingCustomer && !_ctx.getShowingPetRecord)
                        ? (_openBlock(), _createElementBlock("p", _hoisted_60, [
                            _createElementVNode("a", {
                              onClick: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (_ctx.cancelEditing && _ctx.cancelEditing(...args))),
                              class: "button is-light"
                            }, _toDisplayString(_ctx.$t('General.Cancel')), 1)
                          ]))
                        : _createCommentVNode("", true),
                      (!_ctx.addingPet && !_ctx.getEditedCustEditingPet && !_ctx.getEditingCustomer && !_ctx.getShowingPetRecord && !_ctx.getCustDetailing)
                        ? (_openBlock(), _createElementBlock("p", _hoisted_61, [
                            _createElementVNode("a", {
                              onClick: _cache[9] || (_cache[9] = 
//@ts-ignore
(...args) => (_ctx.iniCustomerEditing && _ctx.iniCustomerEditing(...args))),
                              class: "button is-primary"
                            }, _toDisplayString(_ctx.$t('General.ModifyData')), 1)
                          ]))
                        : _createCommentVNode("", true),
                      (_ctx.getCustDetailing && !_ctx.getShowingPetRecord)
                        ? (_openBlock(), _createElementBlock("p", _hoisted_62, [
                            _createElementVNode("a", {
                              onClick: _cache[10] || (_cache[10] = 
//@ts-ignore
(...args) => (_ctx.endCustDetailing && _ctx.endCustDetailing(...args))),
                              class: "button is-primary"
                            }, _toDisplayString(_ctx.$t('General.Back')), 1)
                          ]))
                        : _createCommentVNode("", true)
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_63, [
                    (_ctx.addingPet)
                      ? (_openBlock(), _createElementBlock("form", {
                          key: 0,
                          onSubmit: _cache[12] || (_cache[12] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.addPetMeth && _ctx.addPetMeth(...args)), ["prevent"]))
                        }, [
                          _createElementVNode("div", _hoisted_64, [
                            _createElementVNode("h5", _hoisted_65, _toDisplayString(_ctx.$t('CustomerData.AddNewPet')), 1)
                          ]),
                          _createVNode(_component_CustDataPetDataForm, { ref: "refNewPetDataForm" }, null, 512),
                          _createElementVNode("div", _hoisted_66, [
                            _createElementVNode("p", _hoisted_67, [
                              _createElementVNode("button", _hoisted_68, _toDisplayString(_ctx.$t('General.Add')), 1)
                            ]),
                            _createElementVNode("p", _hoisted_69, [
                              _createElementVNode("a", {
                                onClick: _cache[11] || (_cache[11] = 
//@ts-ignore
(...args) => (_ctx.toggleAddingPet && _ctx.toggleAddingPet(...args))),
                                class: "button is-light"
                              }, _toDisplayString(_ctx.$t('General.Cancel')), 1)
                            ])
                          ])
                        ], 32))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("div", _hoisted_70, [
                    (_ctx.getEditedCustEditingPet)
                      ? (_openBlock(), _createElementBlock("form", {
                          key: 0,
                          onSubmit: _cache[14] || (_cache[14] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.editPetMeth && _ctx.editPetMeth(...args)), ["prevent"]))
                        }, [
                          _createElementVNode("div", _hoisted_71, [
                            _createElementVNode("h5", _hoisted_72, _toDisplayString(_ctx.$t('CustomerData.EditPet')), 1)
                          ]),
                          _createVNode(_component_CustDataPetDataForm, { ref: "refEditedPetDataForm" }, null, 512),
                          _createElementVNode("div", _hoisted_73, [
                            _createElementVNode("p", _hoisted_74, [
                              _createElementVNode("button", _hoisted_75, _toDisplayString(_ctx.$t('General.Edit')), 1)
                            ]),
                            _createElementVNode("p", _hoisted_76, [
                              _createElementVNode("a", {
                                onClick: _cache[13] || (_cache[13] = 
//@ts-ignore
(...args) => (_ctx.cancelEditPet && _ctx.cancelEditPet(...args))),
                                class: "button is-light"
                              }, _toDisplayString(_ctx.$t('General.Cancel')), 1)
                            ])
                          ])
                        ], 32))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("div", _hoisted_77, [
                    (_ctx.getShowingPetRecord)
                      ? (_openBlock(), _createElementBlock("form", {
                          key: 0,
                          onSubmit: _cache[15] || (_cache[15] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.endShowingPetRecordMeth && _ctx.endShowingPetRecordMeth(...args)), ["prevent"]))
                        }, [
                          _createElementVNode("div", _hoisted_78, [
                            _createElementVNode("h5", _hoisted_79, _toDisplayString(_ctx.$t('PetData.PetHistoricalRecord')), 1)
                          ]),
                          _createVNode(_component_CustDataPetDataForm, { ref: "refPetRecordDataForm" }, null, 512),
                          _createElementVNode("div", _hoisted_80, [
                            _createElementVNode("p", _hoisted_81, [
                              _createElementVNode("button", _hoisted_82, _toDisplayString(_ctx.$t('General.Back')), 1)
                            ])
                          ])
                        ], 32))
                      : _createCommentVNode("", true)
                  ])
                ], 32))
              : _createCommentVNode("", true),
            (_ctx.getShowingAppointmentData)
              ? (_openBlock(), _createBlock(_component_AppointmentDataForm, { key: 1 }))
              : _createCommentVNode("", true)
          ])
        ])
      ])
    ])
  ]))
}